<template>
  <div class="notification-container">
    <h1>Bildirim Gönder</h1>
    <form @submit.prevent="sendNotification">
      <div class="form-group">
        <label for="title">Başlık</label>
        <input
          type="text"
          v-model="notification.title"
          id="title"
          class="input"
          placeholder="Bildirim Başlığı"
          required
        />
      </div>

      <div class="form-group">
        <label for="body">İçerik</label>
        <textarea
          v-model="notification.body"
          id="body"
          class="textarea"
          placeholder="Bildirim İçeriği"
          required
        ></textarea>
      </div>

      <button type="submit" class="btn">Gönder</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notification: {
        title: '',
        body: '',
        topic: '', // Topic or device token
      },
    };
  },
  methods: {
    async sendNotification() {
      // You should secure your messaging server. Firebase should not be used in client-side to send notifications.
      const notificationData = {
        title: this.notification.title,
        body: this.notification.body,
      };

      // Firebase function or external service will handle the notification sending
      try {
        const response = await fetch('https://us-central1-falaxy-f10be.cloudfunctions.net/sendNotification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(notificationData),
        });

        if (response.ok) {
          alert('Notification sent successfully!');
        } else {
          alert('Failed to send notification');
        }
      } catch (error) {
        console.error('Error sending notification:', error);
      }
    },
  },
};
</script>

<style scoped>
.notification-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.input,
.textarea {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
}

.btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: #45a049;
}
</style>
